import notaryIcon from "../assets/images/services/notary-icon.svg";
import rushIcon from "../assets/images/services/rush-icon.svg";
import fraudIcon from "../assets/images/services/fraud-icon.svg";
import verifyIdIcon from "../assets/images/services/verify-id-icon.svg";
import sectionImage from "../assets/images/dealers-section-image.svg";
import bannerImageDesktop from "../assets/images/dealer-banner.png";
import bannerImageMobile from "../assets/images/dealer-banner-mobile.png";

export const seo = {
  title: 'Tailored compliance solutions for auto dealers | Mavsign',
  description: 'Navigate compliance effortlessly with our versatile solutions. Crafted specifically for the dynamic needs of auto dealers seeking streamlined operations.',
}

export const services = [
  { icon: notaryIcon,
    title: 'State Commissioned Notaries',
    description: [`<ul><li>Keep the signing process on track for fast and safe signings.</li><li>Witness to document wet signings and points out potential Red Flags.</li><li>Dealer’s liaison in client interaction.</li></ul>`]
  },
  { icon: rushIcon,
    title: 'Rush',
    description: [`<ul><li>Standard contract turnaround time 3 to 5 business days; 1 to 2 days with Rush.</li><li>Mavsign Scheduler coordinates the Order from the second it is added.</li><li>Real-time responses for Remote and Instore VerifyID.</li></ul>`]
  },
  { icon: fraudIcon,
    title: 'Fraud Mitigation',
    description: [`<ul><li>Following your dealership’s rules on Red Flag compliances.</li><li>Extra Mavsign due diligence on any potential fraud.</li><li>A specialized Fraud Team that analyzes probable cases of attempted fraud.</li></ul>`]
  },
  { icon: verifyIdIcon,
    title: 'ID Verification',
    description: [`<ul>
    <li>Remote or in store identity verification available.</li>
    <li>Customer thumbprint collected with our proprietary Affidavit of Identity.</li>
    <li>Negative responses on ID verifications are sent to our team of forensic experts for manual review.</li>
    </ul>`]
  },
];

export const topBanner = {
  title: 'Scale up your Dealership',
  image: {
    mobile: bannerImageMobile,
    desktop: bannerImageDesktop,
  },
  eventAction: 'request_demo_event_dealers',
  eventLabel: 'Request Demo Button - For Dealers',
  alt: 'Car dealer on the showroom floor holding contract',
  description: 'We, at Mavsign, understand car dealerships’ priorities when closing out of state deals – <strong>a trusted ID verification, an error-free signing, and sealed documents.</strong> The Mavsign team and platform provide all the tools you need for a smooth and fast signing. Many stores have experienced an increase of their monthly sales from out of state customers. If e-contracting is already part of your workflow, Mavsign complements with the wet signatures on your contracts, client ID validation, and last but not least, sealed documents. Learn how to safely expand your market beyond the dealership lot and expedite all processes involved in contract signing with the leader and nationwide experts in offsite automotive contract signings.'
}

export const opportunitiesSection = {
  title: 'Why your car dealership should register with us',
  image: sectionImage,
  alt: 'Customer driver’s license on phone screen to be checked for dealer compliance',
}

export const getStoreOptions = [
  {
    label: 'Yes',
    value:  1,
  },
  {
    label: 'No',
    value: 2,
  },
  {
    label: 'Not sure',
    value: 3,
  }
];

export const getGroupManagerStoreManager = [
  {
    label: 'Group Manager (if you manage multiple stores)',
    value: 1,
  },
  {
    label: 'Store Manager (I manage the account for one store)',
    value: 2,
  },
  {
    label: 'I have a different role',
    value: 3,
  }
];

export const getCompanySizeOptions = [
  {
    label: '1-50 employees',
    value: '1-50',
  },
  {
    label: '51-500 employees',
    value: '51-500',
  },
  {
    label: '501-1000 employees',
    value: '501-1000',
  },
  {
    label: '1000+ employees',
    value: '1000+',
  }];

export const youtubeVideoUrl = {
  url: 'https://www.youtube.com/embed/xDbwjTT22n8?si=d6o9bdcL82nuN2VH&rel=0',
  eventAction: 'youtube_video_event_dealers',
  eventLabel: 'Youtube Video - For Dealers',
};

export const requestDemoInfo = {
  description: `This demo request form is intended <strong>only for dealers.</strong><br>
              If you are not a dealer, please use the <a href="/contact-us#contact-form">Contact us form</a> for more information.`
}
