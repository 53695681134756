import React, {useState} from "react";
import {Col, Input, Label, Row, Form, Modal, ModalBody, Button, FormFeedback, Alert} from "reactstrap";
import PropTypes from "prop-types";
import requestDemoImage from "../../assets/images/request-demo-image.png";
import {useFormik} from "formik";
import * as Yup from "yup";
import regx from "../../constants/regx";
import FormMessage from "./FormMessage";
import {successMessages} from "../../constants/form-messages";
import ReactGA from "react-ga4";
import InfoMessage from "./InfoMessage";
import {requestDemoInfo} from "constants/dealers";


const RequestDemoModal = ({isOpen, toggle, closeModal}) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  /********** FORM CONFIG **********/
  const formInitialValues = {
    firstName: '',
    lastName: '',
    interest: '',
    email: '',
    phone: '',
    companyName: '',
    remoteCustomersEstimate: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('Mandatory field'),
      lastName: Yup.string().trim().required('Mandatory field'),
      interest: Yup.string().required('Mandatory field'),
      email: Yup.string().trim().required('Mandatory field').email('Please enter a valid email address'),
      phone: Yup.string().trim().required('Mandatory field').matches(regx.phone, 'Invalid phone number'),
      companyName: Yup.string().trim().required('Mandatory field'),
      remoteCustomersEstimate: Yup.number()
        .typeError('Please insert only numbers') // Custom error message for non-numeric values
        .required('Mandatory field'),
    }),
    onSubmit: values => handleRequestDemo(values),
  });

  /********** OTHER **********/
  const handleRequestDemo = async values => {
    try {
      const apiUrl = `${process.env.GATSBY_API_URL}/in/marketing/request-demo?authType=4&authToken=${process.env.GATSBY_MAV2_API_TOKEN}`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        closeModal();
        setIsSuccessOpen(true);
        formik.resetForm();
        formik.setSubmitting(false);
      } else {
        setIsErrorVisible(true);
      }
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const handleRequestDemoForm = () => {
    ReactGA.event({
      category: 'Button',
      action: 'request_demo_form_event',
      label: 'Request Demo Form Button'
    });
  }

  return <React.Fragment>
    <Modal className="custom-modal" isOpen={isOpen} toggle={toggle} centered fade={true}>
      <div className="custom-modal-header">
        <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={closeModal} />
        <h1 className="title">Request a demo</h1>
      </div>
      <ModalBody className="custom-modal-body">
        <InfoMessage description={requestDemoInfo.description} />
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col sm={12} md={6}>
              <Col className="mb-3">
                <Label>First Name*</Label>
                <Input type="text"
                       placeholder="First Name"
                       name="firstName"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.firstName}
                       invalid={formik.touched.firstName && !!formik.errors.firstName && !!formik.errors.firstName}
                />
                {formik.touched.firstName && !!formik.errors.firstName && <FormFeedback type="invalid">{formik.errors.firstName}</FormFeedback>}
              </Col>
              <Col className="mb-3">
                <Label>Last Name*</Label>
                <Input type="text"
                       placeholder="Last Name"
                       name="lastName"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.lastName}
                       invalid={formik.touched.lastName && !!formik.errors.lastName}
                />
                {formik.touched.lastName && !!formik.errors.lastName && <FormFeedback type="invalid">{formik.errors.lastName}</FormFeedback>}
              </Col>
              <Col className="mb-3">
                <Label>Company Name*</Label>
                <Input type="text"
                       placeholder="Company Name"
                       className="form-control"
                       name="companyName"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.companyName}
                       invalid={formik.touched.companyName && !!formik.errors.companyName}
                />
                {formik.touched.companyName && !!formik.errors.companyName && <FormFeedback type="invalid">{formik.errors.companyName}</FormFeedback>}
              </Col>
              <Col className="mb-3">
                <Label>Email Address*</Label>
                <Input type="text"
                       placeholder="Email"
                       name="email"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.email}
                       invalid={formik.touched.email && !!formik.errors.email}
                />
                {formik.touched.email && !!formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
              </Col>
              <Col className="mb-3">
                <Label>Phone Number*</Label>
                <Input type="text"
                       placeholder="Phone Number"
                       name="phone"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.phone}
                       invalid={formik.touched.phone && !!formik.errors.phone}
                />
                {formik.touched.phone && !!formik.errors.phone && <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>}
              </Col>
              <Col className="mb-3">
                <Label>What best describes your interest in Mavsign?*</Label>
                <Input type="text"
                       placeholder="Interest in Mavsign"
                       className="form-control"
                       name="interest"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.interest}
                       invalid={formik.touched.interest && !!formik.errors.interest}
                />
                {formik.touched.interest && !!formik.errors.interest && <FormFeedback type="invalid">{formik.errors.interest}</FormFeedback>}
              </Col>
              <Col className="mb-3">
                <Label>Estimated remote customers per month*</Label>
                <Input type="text"
                       placeholder="Ex: 100, 100 - 150"
                       className="form-control"
                       name="remoteCustomersEstimate"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.remoteCustomersEstimate}
                       invalid={formik.touched.remoteCustomersEstimate && !!formik.errors.remoteCustomersEstimate}
                />
                {formik.touched.remoteCustomersEstimate && !!formik.errors.remoteCustomersEstimate && <FormFeedback type="invalid">{formik.errors.remoteCustomersEstimate}</FormFeedback>}
              </Col>
            </Col>
            <Col sm={4} className="ps-5">
              <img src={requestDemoImage} alt="request demo" className="request-demo-image" />
            </Col>
          </Row>
          {isErrorVisible && <Alert color="danger" className="fade show text-center mb-0 mt-4">
            <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to send data
          </Alert>
          }
          <Button onClick={handleRequestDemoForm} className="mt-4" type="submit" color="primary" size="lg" block disabled={formik.isSubmitting}>
            Request demo
          </Button>
        </Form>
      </ModalBody>
    </Modal>
    <FormMessage isOpen={isSuccessOpen} closeModal={() => setIsSuccessOpen(false)} image={successMessages.icon.src} title={successMessages.title} description={successMessages.description} />
  </React.Fragment>
}

RequestDemoModal.propsType = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  closeModal: PropTypes.func,
}

export default RequestDemoModal;