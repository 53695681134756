import React from 'react';
import successIcon from '../assets/images/success-icon.svg';
import pendingIcon from "../assets/images/pending-icon.svg";

export const successMessages = {
  icon: {
    src: successIcon,
    alt: 'success-form-message'
  },
  title: 'Thank You for Your Interest!',
  description: <>Your form has been successfully submitted.<br/> You’ll be contacted in short time.</>,
}

export const oneStepAwayMessages = {
  icon: {
    src: successIcon,
    alt: 'success-form-message'
  },
  title: 'You\'re one step away!',
  description: 'An email will be sent shortly to complete your registration.',
}

export const pendingMessages = {
  icon: {
    src: pendingIcon,
    alt: 'pending-form-message'
  },
  title: 'Registration is pending',
  description: 'Your information has been sent. An account representative will check them and send you an email with the next steps to log in.',
}