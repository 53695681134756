import React from 'react';
import { Helmet } from 'react-helmet';

const Chatbot = () => {
  return (
    <Helmet defer={false} >
    <script>{`window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 7673357,playbookUuid: '02708929-9822-418f-a462-719e133ff0c3',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();`}</script>
    <script src="https://leadbooster-chat.pipedrive.com/assets/loader.js" async></script>
  </Helmet>
  );
};

export default Chatbot;
