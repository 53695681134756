import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import "./src/assets/scss/theme.scss";
import Header from "./src/components/Shared/Header";
import Footer from "./src/components/Shared/Footer";
import Chatbot from "./src/components/Shared/Chatbot";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga4';
import ConsentBanner from "./src/components/Shared/ConsentBanner";
import { useCookies } from 'react-cookie';

const Layout = props => {
 
  const [cookies] = useCookies(['gatsby-gdpr-google-analytics']);

  // Initialize ReactGA only on the client-side
  useEffect(() => {
    if (typeof window !== 'undefined' && cookies['gatsby-gdpr-google-analytics'] === 'true') {
      ReactGA.initialize('G-MRZ40PWVVX')
    }
  }, [cookies])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Chatbot />
        <Header/>
        <div className="main-content mk-content">{props.children}</div>
        <Footer />
        <ReCAPTCHA className="recaptcha" size={"invisible"} sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}/>
      </div>
      <ConsentBanner />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
