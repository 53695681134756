import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from "react-ga4";

const ConsentBanner = () => {

  /********** STATE **********/
  const [cookies, setCookie] = useCookies(['gatsby-gdpr-google-analytics']);
  const [showBanner, setShowBanner] = useState(false);

  /********** EFFECTS **********/
  useEffect(() => {
    if (cookies['gatsby-gdpr-google-analytics'] === undefined) {
      setShowBanner(true)
    }
  }, [cookies])

  /********** OTHER **********/
  const handleAcceptClick = () => {
    // Track the event when the "Accept" button is clicked
    ReactGA.event({
      event: 'accept_cookies_event',
      category: 'Button',
      action: 'accept_cookies_event',
      label: 'Accept cookies event'
    });
  }

  const handleDeclineClick = () => {
    // Push the decline event to the data layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'decline_cookies_event',
      category: 'Button',
      action: 'decline_cookies_event',
      label: 'Decline cookies event'
    });
  }

  const handleAccept = () => {
    setCookie('gatsby-gdpr-google-analytics', true, { path: '/' });
    handleAcceptClick();
    setShowBanner(false);
  }

  const handleDecline = () => {
    setCookie('gatsby-gdpr-google-analytics', false, { path: '/' });
    handleDeclineClick();
    setShowBanner(false);
  }

  if (!showBanner) return null

  return (
    <div style={{ position: 'fixed', bottom: 0, width: '100%', background: 'black', color: 'white', padding: '1rem', textAlign: 'center', zIndex:'10' }}>
      <p>We use cookies to improve your experience on our site. Do you accept?</p>
      <button className='accept-cookies btn btn-primary' onClick={handleAccept}>Accept</button>
      <button className='decline-cookies btn btn-primary ms-2' onClick={handleDecline}>Decline</button>
    </div>
  )
}

export default ConsentBanner
