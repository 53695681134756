import React, { useState, useEffect } from "react";
import { Link } from 'gatsby';
import logoIcon from 'assets/images/logo.svg';
import starIcon from 'assets/images/star-icon.svg';
import whiteLogoIcon from 'assets/images/whiteLogo.svg';
import appleStoreIcon from 'assets/images/app-store.svg';
import mobileMenuIcon from 'assets/images/mobile-menu-icon.svg';
import mobileMenuCloseIcon from 'assets/images/mobile-menu-close-icon.svg';
import phoneCallIcon from 'assets/images/call.svg';
import RequestDemoModal from "./RequestDemoModal";
import {downloadAppStoreUrl} from '../../constants/layout';
import ReactGA from "react-ga4";

const Header = () => {

  const pages = [
    {
      name: 'Company',
      link: '/',
    },
    {
      name: "What's New?",
      link: '/news',
      hasIcon: true,
    },
    {
      name: 'Pricing',
      link: '/pricing',
    },
    {
      name: 'Services',
      link: '/services'
    },
    {
      name: 'For Dealers',
      link: '/dealers'
    },
    {
      name: 'For Notaries',
      link: '/notaries'
    },
    {
      name: 'Contact us',
      link: '/contact-us'
    },
  ];

  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState(logoIcon);
  const [navbar, setNavbar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);

  //logo scroll function
  const changeHeader = () => {
    if (window.scrollY >= 55) {
      setNavbarLogo(whiteLogoIcon);
      setNavbar(true);
    } else {
      setNavbarLogo(logoIcon);
      setNavbar(false);
    }
  }

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const closeMegaMenu = () => setIsMegaMenuOpen(false);

  useEffect(() => {
    changeHeader();
    // adding the event when scroll change Header
    window.addEventListener("scroll", changeHeader);
  })

  const handleRequestDemoClick = () => {
    // Track the event when the "Request Demo" button is clicked
    ReactGA.event({
      category: 'Button',
      action: 'request_demo_event_header',
      label: 'Request Demo Button - Header'
    });
    
    setIsModalOpen(true);
    closeMegaMenu();
  }

  const handleLoginMav2 = () => {
    ReactGA.event({
      category: 'Button',
      action: 'login_MAV2_event',
      label: 'Login to MAV2 Button'
    });

    closeMegaMenu();
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className='header'>
        <div className={"navbar-header justify-content-around " + (navbar ? "sticky-header" : "")}>
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className='logo'>
                <span className="logo-lg">
                  <img src={navbarLogo} alt="mavsign logo" />
                </span>
              </Link>
            </div>

            {/* Mobile menu  */}
            <div className="mobile-menu-wrapper">
              <button className="mobile-menu-icon" onClick={() => setIsMegaMenuOpen(!isMegaMenuOpen)}>
                <img src={mobileMenuIcon} className="icon open-icon"/>
              </button>
              <div className={"mobile-menu " + (isMegaMenuOpen ? 'is-open' : 'is-close')}>
                <div className="mobile-menu-links">
                  <div className="menu-header">
                    <button onClick={closeMegaMenu} className="close-button">
                      <img src={mobileMenuCloseIcon} className="icon close-icon"/>
                    </button>
                    <a onClick={handleLoginMav2} href={process.env.GATSBY_AUTH_URL} target="_blank" rel="noopener noreferrer" className="btn btn-sm me-3 px-3 login-btn">
                      Log In
                    </a>
                  </div>
                  <ul className="menu-list list-unstyled ">
                    {pages.map((page, index) => <li key={index}>
                      <Link to={page.link} 
                            id={index} 
                            activeClassName='active' 
                            onClick={closeMegaMenu}
                      > {page.name} {page.hasIcon && <img src={starIcon} alt="star" className="star-icon" />}
                      </Link>
                    </li>)}
                  </ul>
                  <div className="menu-footer">
                    <button onClick={handleRequestDemoClick} className={"btn btn-sm px-3 request-demo-btn w-100"}>Request Demo</button>
                    <a href={downloadAppStoreUrl.url}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="app-store-btn"
                        onClick={closeMegaMenu}
                      >
                        <img src={appleStoreIcon} alt="mavsign logo" />
                    </a>
                  </div>
                  <div className="phone-call d-flex">
                    <img src={phoneCallIcon} className="me-3" alt="phone-call-icon" />
                    <div className="phone-call-info">
                      <div className="phone-call-title">Get in contact with us</div>
                      <a onClick={closeMegaMenu} href="tel:+1 (877) 708-4383" className="phone-call-number">+1 (877) 708-4383</a>
                    </div>
                  </div>
                </div>
                <button className="mobile-menu-overlay" onClick={closeMegaMenu}/>
              </div>
            </div>
          </div>

          <div className='custom-menu d-flex'>
              {pages.map((page, index) => <Link key={index} to={page.link} id={index} className="header-link me-3" activeClassName='header-link-active'>
                {page.hasIcon && <img src={starIcon} alt="star" className="star-icon" />}{page.name}</Link>)}
          </div>
          <div className='custom-buttons-wrapper d-flex'>
            <a onClick={handleLoginMav2} href={process.env.GATSBY_AUTH_URL} target="_blank" rel="noopener noreferrer" className="btn btn-sm me-3 px-3 login-btn">
              Log In
            </a>
            <button onClick={handleRequestDemoClick} className={"btn btn-sm px-3 request-demo-btn"}>Request Demo</button>
          </div>
          <div className='custom-mobile-logo'>
            <Link to="/" className='logo'>
              <img src={logoIcon} alt="mavsign logo" />
            </Link>
          </div>
        </div>
      </header>
      <RequestDemoModal isOpen={isModalOpen} toggle={toggleModal} closeModal={() => setIsModalOpen(false)} />
    </React.Fragment>
  );
};

export default Header;

