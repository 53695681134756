import React from "react";
import {Modal} from "reactstrap";
import PropTypes from "prop-types";


const FormMessage = ({isOpen, toggle, closeModal, image, title, description}) => {
  return <Modal className="custom-modal form-message" isOpen={isOpen} toggle={toggle} centered fade={true}>
    <div className="custom-modal-header">
      <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={closeModal} />
      <img src={image} className="form-message-image mb-4" alt="form-message" />
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
    </div>
  </Modal>
}

FormMessage.propsType = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  closeModal: PropTypes.func,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default FormMessage;