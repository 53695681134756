const layoutTypes = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
  }
  
  const layoutWidthTypes = {
    FLUID: "fluid",
    BOXED: "boxed",
    SCROLLABLE: "scrollable",
  }
  
  const topBarThemeTypes = {
    LIGHT: "light",
    DARK: "dark",
    COLORED: "colored",
  }
  
  const downloadAppStoreUrl = {
    url: 'https://apps.apple.com/us/app/mavsign-2-0-instore-idcheck/id6479606763'
  }

  export {
    layoutTypes,
    layoutWidthTypes,
    topBarThemeTypes,
    downloadAppStoreUrl
  }
  