import React from "react";
import PropTypes from "prop-types";
import infoIcon from "assets/images/blue-info-icon.svg";

const InfoMessage = ({ description }) => {

  return(<div className="request-demo-info w-100">
    <img src={infoIcon} className="me-2" />
    <div dangerouslySetInnerHTML={{ __html: description }} />
  </div>)
}

InfoMessage.propTypes = {
  description: PropTypes.string,
}

export default InfoMessage;